'use client';

import { Amplify } from 'aws-amplify';
import { AppUIProvider } from '@alfred-co/ui/providers';
import awsconfig from '@/aws-exports';
import { ReactNode, useEffect } from 'react';
import { Toaster } from 'sonner';
import useAuth from './(auth)/hooks/useAuth';
import Loading from './loading';

Amplify.configure(awsconfig, { ssr: true });

export function Providers ({ children }: { children: ReactNode }) {
  const { user, getMeUser } = useAuth();

  useEffect(() => {
    if (!user) {
      void getMeUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppUIProvider>
      <>
        {
        !user
          ? (<div className='min-h-screen'><LoadingClient /></div>)
          : children
        }
        <Toaster
          toastOptions={{
            classNames: {
              error: 'bg-danger text-white',
            },
            duration: 4000,
          }}
        />
      </>
    </AppUIProvider>
  );
}

const LoadingClient = () => {
  return (
    <main className='flex min-h-screen w-full'>
      <div className='flex flex-grow flex-col bg-dark-gradient'>
        <Loading />
      </div>
    </main>
  );
};
