import Image from 'next/image';

const Loading = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <Image src='/assets/images/loader.gif' alt='loading' width={150} height={150} />
    </div>
  );
};

export default Loading;
